.auth .button-submit {
  color: #3a60af;
  background-color: #ecf2fa;
  border-color: #ecf2fa;
}

.auth .button-submit:hover,
.auth .button-submit:active,
.auth .button-submit:focus {
  color: #3a60af;
  background-color: #c9dbf3;
  border-color: #c9dbf3;
}

.auth fieldset {
  border-color: #e4e4e4 !important;
  border-width: 1px !important;
}
