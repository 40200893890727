.szh-menu-button{padding:0;background:rgba(0,0,0,0);border:none}

.editor-dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editor-dialog .header {
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}
.editor-dialog .body {
  max-width: 740px;
  width: 100%;
  padding: 20px;
  font-size: 21px;
  box-sizing: border-box;
}
.dialog-fake.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  touch-action: none;
  background-color: rgba(0,0,0,0.5);
  -webkit-tap-highlight-color: transparent;
}


.image-widget-item {
  background: no-repeat 50%;
  background-size: cover;
  border-radius: 3px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  vertical-align: top;
  display: inline-block;
}
.cart-content {
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.cart-actions {
  flex: 0 0 auto;
}
.button-checkout {
  border-radius: 10px 0 0 10px !important;
  background-color: #c1caec !important;
  opacity: 0.8 !important;
}
.button-checkout:hover,
.button-checkout:active,
.button-checkout:focus {
  opacity: 1 !important;
}
@media screen and (max-width: 767px) {
  .cart-content .name h2 {
    font-size: 18px;
  }
}

.cart-widget {
  background-color: #fff !important;
  border-left: 1px solid #eee !important;
}

.auth .button-submit{color:#3a60af;background-color:#ecf2fa;border-color:#ecf2fa}.auth .button-submit:hover,.auth .button-submit:active,.auth .button-submit:focus{color:#3a60af;background-color:#c9dbf3;border-color:#c9dbf3}.auth fieldset{border-color:#e4e4e4 !important;border-width:1px !important}
.auth .button-submit{color:#3a60af;background-color:#ecf2fa;border-color:#ecf2fa}.auth .button-submit:hover,.auth .button-submit:active,.auth .button-submit:focus{color:#3a60af;background-color:#c9dbf3;border-color:#c9dbf3}.auth fieldset{border-color:#e4e4e4 !important;border-width:1px !important}
.loading-box {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

